import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Link, useStaticQuery, graphql } from "gatsby";

import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";

const Container = styled.div`
  border-top: 4px solid #f1a857;
  border-bottom: 1px solid #ffffff;

  position: relative;

  .filter-results {
    font-size: 16px;
    color: #40403f;
    letter-spacing: 0.36px;
    font-weight: 600;
    padding-top: 24px;
    padding-bottom: 24px;
    width: 100%;
    background: transparent;

    display: flex;
    align-items: center;
    justify-content: center;

    i.icon-filter {
      display: none;
      width: 20px;
      height: 20px;
      margin-right: 7px;
      background: url(/images/streamline-icon-filter-1@140x140@2x-branco.png)
        no-repeat center;
      background-size: contain;
    }

    i.icon-arrow {
      background: url(/images/arrow-down.png) no-repeat center;
      background-size: contain;
      margin-left: 13px;
      width: 11px;
      height: 15px;
    }
    &.open {
      background: #f1a857;
      color: #fff;

      i.icon-filter {
        display: inline-block;
      }

      i.icon-arrow {
        background: url(/images/arrow-right.png) no-repeat center;
        width: 15px;
        transform: rotate(-90deg);
      }
    }
  }

  .filter-options {
    position: absolute;
    top: calc(100% + 1px);
    width: 100%;

    box-shadow: 0 20px 30px 0px rgba(0, 0, 0, 0.5);
    background-color: #d8d8d8;
    z-index: 1;

    ul {
      & > li {
        border-bottom: 1px solid #ffffff;

        .header-title {
          display: flex;
          align-items: center;
          justify-content: center;
          flex-wrap: wrap;
          padding-top: 25px;
          padding-bottom: 25px;

          > span {
            color: #40403f;
            font-size: 14px;
            letter-spacing: 0.32px;
            font-weight: 600;
          }

          .icon {
            width: 20px;
            height: 20px;
            margin-right: 10px;
            display: inline-block;
            background-size: contain;
            background-repeat: no-repeat;
            background-position: center;
            vertical-align: middle;
            &.icon-type {
              background-image: url(/images/streamline-icon-project-blueprint-create140x140.png);
            }
            &.icon-design {
              background-image: url(/images/streamline-icon-color-palette-sample-2@40x40@2x.png);
            }
            &.icon-mdf {
              background-image: url(/images/streamline-icon-layers-stacked-1@40x40@2x.png);
            }
            &.icon-hue {
              background-image: url(/images/streamline-icon-color-palette@40x40@2x.png);
            }
          }

          .icon-arrow {
            width: 11px;
            height: 15px;
            background: url(/images/arrow-down.png) no-repeat center;
            vertical-align: middle;
            margin-left: 15px;

            &.active-arrow {
              transform: rotate(180deg);
            }
          }
        }

        &:first-child,
        &:last-child {
          display: none;
        }

        .sub-item {
          padding-right: 20px;
          display: flex;
          width: 100%;
          padding-bottom: 26px;
          overflow-x: auto;

          .container {
            padding: 0 20px;
            display: flex;
            margin: 0 auto;
            flex-wrap: wrap;

            @media (min-width: 767px) {
              max-width: 767px;
            }

            @media (min-width: 1200px) {
              max-width: 1200px;
              padding: 0 15px;
            }
          }
        }

        .item {
          width: 50%;
          margin-bottom: 20px;
          color: #40403f;
          display: flex;
          align-items: center;

          @media (min-width: 767px) {
            width: 33%;
          }

          @media (min-width: 1200px) {
            width: 20%;
          }

          div {
            display: flex;
            align-items: center;
          }

          i {
            border: 2px solid #40403f;
            width: 20px;
            height: 20px;
            margin-right: 15px;
            display: inline-block;
            background: transparent;
            margin-left: 6px;
            appearance: none;
            border-radius: 3px;

            &.active {
              border: 2px solid #40403f;
              background: url(/images/check.svg) no-repeat center #40403f;
            }
          }
        }

        .box-option {
          border-bottom: 0;
          margin-right: 7px;
          min-width: 160px;
          max-width: 160px;
          height: 100px;
          border-radius: 3px;
          padding: 0;
          background-color: #9e9e9e;

          background-size: cover;
          background-position: center;

          div {
            padding: 10px 13px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            cursor: pointer;

            height: 100%;
            width: 100%;

            span {
              font: italic bold 20px "Times new roman";
              color: #ffffff;
            }

            i {
              border: 2px solid #ffffff;
              min-width: 20px;
              height: 20px;
              background: transparent;
              margin-left: 6px;
              appearance: none;
              border-radius: 3px;

              &.active {
                border: 2px solid #ffffff;
                background: url(/images/check.svg) no-repeat center #fff;
              }
            }
          }

          &:first-child {
            margin-left: 20px;
          }

          &:first-child,
          &:last-child {
            display: flex;
          }
        }

        .mobile-carousel {
          padding-bottom: 25px;

          ul.react-multi-carousel-track {
            padding-bottom: 35px;

            li {
              border-bottom: 0;

              .box-option {
                margin-right: 0;
                margin-left: 7px;
                max-width: 100%;
              }

              &:last-child,
              &:first-child {
                display: block;
              }
            }
          }

          ul.dots-carousel {
            li {
              border-bottom: 0;

              button {
                width: 8px;
                border: 1px solid #f1a857;
                height: 8px;
                background: transparent;
              }

              &.react-multi-carousel-dot--active button {
                background: #f1a857;
              }

              &:last-child,
              &:first-child {
                display: block;
              }
            }
          }
        }
      }
    }
  }

  @media (min-width: 767px) {
    .filter-results {
      display: none;
    }

    .filter-options {
      position: relative;

      box-shadow: none;
      top: 0;

      ul {
        display: flex;
        align-items: center;
        justify-content: space-between;

        max-width: 767px;
        width: 100%;
        padding: 0 20px;
        margin: 0 auto;

        li {
          border-right: 1px solid #ffffff;
          border-bottom: 0;
          max-width: 17%;

          .header-title {
            color: #40403f;
            font-size: 14px;
            letter-spacing: 0.32px;
            font-weight: 400;

            width: 100%;
            padding: 0 20px;
            height: 60px;
            justify-content: center;
            align-items: center;
            display: flex;

            > span {
              text-align: center;
            }

            .icon-arrow {
              display: none;

              &.active-arrow {
                transform: rotate(0);
              }
            }

            .icon {
              display: none;
            }
          }

          &:first-child,
          &:last-child {
            display: flex;
          }

          &:last-child {
            border-right: 0;
            padding-right: 0;
            padding-left: 0;
            justify-content: flex-end;
            margin-left: auto;

            a {
              cursor: pointer;
              color: #40403f;
              font-size: 14px;
              letter-spacing: 0.32px;
              font-weight: 600;
              padding-right: 15px;
              padding-left: 15px;

              background: transparent;

              display: flex;
              align-items: center;
              text-align: left;
              justify-content: flex-end;

              height: 60px;
              width: 100%;

              span {
                width: 60px;
              }

              i {
                width: 20px;
                height: 20px;
                display: inline-block;
                background: url(/images/streamline-icon-keyboard-arrow-return@40x40@2x.png)
                  no-repeat center;
                background-size: contain;
                margin-right: 12px;
              }

              &:hover {
                background: #fff;
              }
            }
          }

          &:first-child {
            padding-left: 0;
            max-width: 90px;
            padding-right: 20px;
            height: 60px;
            align-items: center;

            color: #40403f;
            font-size: 14px;
            letter-spacing: 0.32px;
            font-weight: 400;
          }

          &:hover {
            .header-title {
              background: #ffffff;
            }

            .sub-item {
              display: flex;
            }
          }

          .sub-item {
            display: none;
            position: absolute;
            background: #e9e9e9;
            width: 100%;
            max-width: 100%;
            padding: 20px;

            top: calc(100% + 1px);
            left: 0;
            right: 0;
            margin: 0;

            justify-content: flex-start;

            &.mdf {
              flex-wrap: wrap;

              .box-option {
                margin-bottom: 7px;
                flex: 1;
                min-width: 160px;
                max-width: inherit;
              }
            }
          }

          .box-option {
            &:first-child {
              margin-left: 0;
            }

            &:last-child {
              margin-left: 0;
            }

            &:hover {
              opacity: 0.8;
            }
          }
        }
      }
    }
  }

  @media (min-width: 1200px) {
    .filter-options {
      ul {
        max-width: 1200px;
        padding: 0 15px;

        justify-content: flex-start;

        li {
          max-width: inherit;
          .header-title {
            padding: 0 10px;

            .icon-arrow {
              margin-left: 11px;
              display: block;
            }

            > span {
              font-size: 14px;
            }

            .icon {
              display: inline-block;
            }
          }

          &:last-child {
            a span {
              width: 58px;
            }
          }

          &:first-child {
            max-width: 130px;
            padding-right: 15px;
            justify-content: flex-start;
          }

          &:nth-child(2) {
            max-width: 251px;
          }
          &:nth-child(3) {
            max-width: 242px;
          }
          &:nth-child(4) {
            max-width: 220px;
          }
        }
      }
    }
  }
`;

export default function FilterProjects(props) {
  const results = useStaticQuery(graphql`
    {
      tipo_projeto: allPrismicTipoDeProjeto {
        edges {
          node {
            data {
              imagem {
                url
              }
              titulo {
                text
              }
            }
            uid
          }
        }
      }
      tonalidade_produto: allPrismicTonalidadeProduto(
        filter: { lang: { eq: "pt-br" } }
      ) {
        edges {
          node {
            data {
              imagem {
                url
              }
              nome {
                text
              }
            }
            uid
          }
        }
      }
      design_do_produto: allPrismicDesignDoProduto(
        filter: { lang: { eq: "pt-br" } }
      ) {
        edges {
          node {
            data {
              imagem {
                url
              }
              nome {
                text
              }
            }
            uid
          }
        }
      }
      mdf: allPrismicProduto(
        sort: { fields: data___nome___text, order: ASC }
        filter: {
          data: { tipo_de_produto: { slug: { in: ["decorativos", "aris"] } } }
          lang: { eq: "pt-br" }
        }
      ) {
        edges {
          node {
            data {
              imagem_principal {
                url
              }
              nome {
                text
              }
            }
            uid
          }
        }
      }
    }
  `);

  const { design_do_produto, mdf, tonalidade_produto, tipo_projeto } = results;

  const [openResultsMobile, setOpenResultsMobile] = useState(false);
  const [isMobile, setIsMobile] = useState(false);

  const [typeProject, setTypeProject] = useState(props.tipo);
  const [designProduto, setDesignProduto] = useState(props.design);
  const [tonalidadeProduto, setTonalidadeProduto] = useState(props.tonalidade);
  const [mdfSelected, setMDF] = useState(props.mdfFilter);
  const [is3d, setIs3d] = useState(props.is3d);

  const [typeActive, setTypeActive] = useState(false);
  const [mdfActive, setMdfActive] = useState(false);
  const [designActive, setDesignActive] = useState(false);
  const [hueActive, setHueActive] = useState(false);
  const [is3dActive, setIs3dActive] = useState(false);

  function toggleMobileResults() {
    setOpenResultsMobile(!openResultsMobile);
  }

  const resize = () => {
    if (document.body.clientWidth > 767) {
      setOpenResultsMobile(true);
      setTypeActive(false);
      setMdfActive(false);
      setHueActive(false);
    } else {
      setOpenResultsMobile(false);
    }
  };

  const resizeCarousel = () => {
    if (document.body.clientWidth > 540) {
      setIsMobile(false);
    } else {
      setIsMobile(true);
    }
  };

  useEffect(() => {
    resize();
    resizeCarousel();

    setTypeProject("");
    setDesignProduto("");
    setTonalidadeProduto("");
    setMDF("");
    setIs3d("");

    window.addEventListener("resize", () => resize);
    window.addEventListener("resize", () => resizeCarousel);

    return () => {
      window.removeEventListener("resize", () => resize);
      window.removeEventListener("resize", () => resizeCarousel);
    };
  }, [props]);

  function toggleMenuType() {
    setTypeActive(!typeActive);
  }

  function toggleMenuMDF() {
    setMdfActive(!mdfActive);
  }

  function toggleMenuHue() {
    setHueActive(!hueActive);
  }

  function toggleMenuDesign() {
    setDesignActive(!designActive);
  }

  function toggleMenuIs3d() {
    setIs3dActive(is3dActive);
  }

  return (
    <Container>
      <button
        className={`${
          openResultsMobile ? "filter-results open" : "filter-results"
        }`}
        onClick={toggleMobileResults}
      >
        <i className="icon-filter"></i>
        <span>
          {openResultsMobile ? "Filtrar resultados" : "Filtrar projetos"}
        </span>
        <i className="icon-arrow"></i>
      </button>

      {openResultsMobile && (
        <div className="filter-options">
          <ul>
            <li>Filtrar por:</li>
            <li>
              <div className="header-title" onClick={toggleMenuType}>
                <i className="icon icon-type"></i>
                <span>Tipo de ambiente</span>
                <i
                  className={
                    typeActive ? "icon-arrow active-arrow" : "icon-arrow"
                  }
                ></i>
              </div>
              {isMobile ? (
                <div className="mobile-carousel">
                  <Carousel
                    additionalTransfrom={0}
                    arrows={false}
                    showDots
                    dotListClass="dots-carousel"
                    centerMode={false}
                    className=""
                    containerClass="container-carousel"
                    draggable
                    focusOnSelect={false}
                    minimumTouchDrag={80}
                    partialVisible
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={{
                      desktop: {
                        breakpoint: {
                          max: 3000,
                          min: 1024,
                        },
                        items: 3,
                        partialVisibilityGutter: 40,
                      },
                      mobile: {
                        breakpoint: {
                          max: 464,
                          min: 0,
                        },
                        items: 2,
                        partialVisibilityGutter: 10,
                      },
                      tablet: {
                        breakpoint: {
                          max: 1024,
                          min: 464,
                        },
                        items: 2,
                        partialVisibilityGutter: 30,
                      },
                    }}
                    slidesToSlide={2}
                    swipeable
                  >
                    {tipo_projeto.edges.map((item, index) => (
                      <a
                        href={
                          props.tipo === item.node.data.titulo.text
                            ? `/ambientes/?tipo-de-projeto=&design=${props.design}&mdf=${props.mdfFilter}&tonalidade=${props.tonalide}`
                            : `/ambientes/?tipo-de-projeto=${item.node.data.titulo.text}&design=${props.design}&mdf=${props.mdfFilter}&tonalidade=${props.tonalide}&is_3d=${props.is3d}`
                        }
                        key={index}
                        className="box-option"
                        style={
                          item.node.data.imagem
                            ? {
                                backgroundImage: `url('${item.node.data.imagem.url}')`,
                              }
                            : null
                        }
                      >
                        <div>
                          <span>{item.node.data.titulo.text}</span>
                          <i
                            className={
                              props.tipo === item.node.data.titulo.text
                                ? "active"
                                : ""
                            }
                          ></i>
                        </div>
                      </a>
                    ))}
                  </Carousel>
                </div>
              ) : (
                <div className="sub-item">
                  {tipo_projeto.edges.map((item, index) => (
                    <a
                      href={
                        props.tipo === item.node.data.titulo.text
                          ? `/ambientes/?tipo-de-projeto=&design=${props.design}&mdf=${props.mdfFilter}&tonalidade=${props.tonalide}`
                          : `/ambientes/?tipo-de-projeto=${item.node.data.titulo.text}&design=${props.design}&mdf=${props.mdfFilter}&tonalidade=${props.tonalide}&is_3d=${props.is3d}`
                      }
                      key={index}
                      className="box-option"
                      style={
                        item.node.data.imagem
                          ? {
                              backgroundImage: `url('${item.node.data.imagem.url}')`,
                            }
                          : null
                      }
                    >
                      <div>
                        <span>{item.node.data.titulo.text}</span>
                        <i
                          className={
                            props.tipo === item.node.data.titulo.text
                              ? "active"
                              : ""
                          }
                        ></i>
                      </div>
                    </a>
                  ))}
                </div>
              )}
            </li>
            <li>
              <div className="header-title" onClick={toggleMenuMDF}>
                <i className="icon icon-mdf"></i>
                <span>MDF Utilizado</span>
                <i
                  className={
                    mdfActive ? "icon-arrow active-arrow" : "icon-arrow"
                  }
                ></i>
              </div>
              {isMobile ? (
                <div className="mobile-carousel">
                  <Carousel
                    additionalTransfrom={0}
                    arrows={false}
                    showDots
                    dotListClass="dots-carousel"
                    centerMode={false}
                    className=""
                    containerClass="container-carousel"
                    draggable
                    focusOnSelect={false}
                    minimumTouchDrag={80}
                    partialVisible
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={{
                      desktop: {
                        breakpoint: {
                          max: 3000,
                          min: 1024,
                        },
                        items: 3,
                        partialVisibilityGutter: 40,
                      },
                      mobile: {
                        breakpoint: {
                          max: 464,
                          min: 0,
                        },
                        items: 2,
                        partialVisibilityGutter: 10,
                      },
                      tablet: {
                        breakpoint: {
                          max: 1024,
                          min: 464,
                        },
                        items: 2,
                        partialVisibilityGutter: 30,
                      },
                    }}
                    slidesToSlide={2}
                    swipeable
                  >
                    {mdf.edges.map((item, index) => (
                      <a
                        href={
                          props.mdfFilter === item
                            ? `/ambientes/?mdf=&design=${props.design}&tipo-de-projeto=${props.tipo}&tonalidade=${props.tonalide}`
                            : `/ambientes/?mdf=${item.node.data.nome[0].text}&design=${props.design}&tipo-de-projeto=${props.tipo}&tonalidade=${props.tonalide}&is_3d=${props.is3d}`
                        }
                        to={`/ambientes/?mdf=${item.node.data.nome[0].text}`}
                        key={index}
                        className="item"
                      >
                        <div>
                          <i
                            className={
                              props.mdfFilter === item.node.uid ? "active" : ""
                            }
                          ></i>
                          <span>{item.node.data.nome[0].text}</span>
                        </div>
                      </a>
                    ))}
                  </Carousel>
                </div>
              ) : (
                <div className="sub-item mdf">
                  <div class="container">
                    {mdf.edges.map((item, index) => (
                      <a
                        href={
                          props.mdfFilter === item
                            ? `/ambientes/?mdf=&design=${props.design}&tipo-de-projeto=${props.tipo}&tonalidade=${props.tonalide}`
                            : `/ambientes/?mdf=${item.node.data.nome[0].text}&design=${props.design}&tipo-de-projeto=${props.tipo}&tonalidade=${props.tonalide}&is_3d=${props.is3d}`
                        }
                        key={index}
                        className="item"
                      >
                        <div>
                          <i
                            className={
                              props.mdfFilter === item.node.data.nome[0].text
                                ? "active"
                                : ""
                            }
                          ></i>
                          <span>{item.node.data.nome[0].text}</span>
                        </div>
                      </a>
                    ))}
                  </div>
                </div>
              )}
            </li>
            <li>
              <div className="header-title" onClick={toggleMenuHue}>
                <i className="icon icon-hue"></i>
                <span>Tonalidade</span>
                <i
                  className={
                    hueActive ? "icon-arrow active-arrow" : "icon-arrow"
                  }
                ></i>
              </div>
              {isMobile ? (
                <div className="mobile-carousel">
                  <Carousel
                    additionalTransfrom={0}
                    arrows={false}
                    showDots
                    dotListClass="dots-carousel"
                    centerMode={false}
                    className=""
                    containerClass="container-carousel"
                    draggable
                    focusOnSelect={false}
                    minimumTouchDrag={80}
                    partialVisible
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={{
                      desktop: {
                        breakpoint: {
                          max: 3000,
                          min: 1024,
                        },
                        items: 3,
                        partialVisibilityGutter: 40,
                      },
                      mobile: {
                        breakpoint: {
                          max: 464,
                          min: 0,
                        },
                        items: 2,
                        partialVisibilityGutter: 10,
                      },
                      tablet: {
                        breakpoint: {
                          max: 1024,
                          min: 464,
                        },
                        items: 2,
                        partialVisibilityGutter: 30,
                      },
                    }}
                    slidesToSlide={2}
                    swipeable
                  >
                    {tonalidade_produto.edges.map((item, index) => (
                      <a
                        href={
                          props.tonalidade === item.node.uid
                            ? `/ambientes/?tonalidade=&design=${props.design}&tipo-de-projeto=${props.tipo}&mdf=${props.mdfFilter}`
                            : `/ambientes/?tonalidade=${item.node.uid}&design=${props.design}&tipo-de-projeto=${props.tipo}&mdf=${props.mdfFilter}&is_3d=${props.is3d}`
                        }
                        key={index}
                        className="box-option"
                        style={
                          item.node.data.imagem.url
                            ? {
                                backgroundImage: `url('${item.node.data.imagem.url}')`,
                              }
                            : null
                        }
                      >
                        <div>
                          <span>{item.node.data.nome[0].text}</span>
                          <i
                            className={
                              props.tonalidade === item.node.uid ? "active" : ""
                            }
                          ></i>
                        </div>
                      </a>
                    ))}
                  </Carousel>
                </div>
              ) : (
                <div className="sub-item">
                  {tonalidade_produto.edges.map((item, index) => (
                    <a
                      href={
                        props.tonalidade === item.node.uid
                          ? `/ambientes/?tonalidade=&design=${props.design}&tipo-de-projeto=${props.tipo}&mdf=${props.mdfFilter}`
                          : `/ambientes/?tonalidade=${item.node.uid}&design=${props.design}&tipo-de-projeto=${props.tipo}&mdf=${props.mdfFilter}&is_3d=${props.is3d}`
                      }
                      key={index}
                      className="box-option"
                      style={
                        item.node.data.imagem.url
                          ? {
                              backgroundImage: `url('${item.node.data.imagem.url}')`,
                            }
                          : null
                      }
                    >
                      <div>
                        <span>{item.node.data.nome[0].text}</span>
                        <i
                          className={
                            props.tonalidade === item.node.uid ? "active" : ""
                          }
                        ></i>
                      </div>
                    </a>
                  ))}
                </div>
              )}
            </li>
            <li>
              <div className="header-title" onClick={toggleMenuDesign}>
                <i className="icon icon-design"></i>
                <span>Design do material</span>
                <i
                  className={
                    designActive ? "icon-arrow active-arrow" : "icon-arrow"
                  }
                ></i>
              </div>
              {isMobile ? (
                <div className="mobile-carousel">
                  <Carousel
                    additionalTransfrom={0}
                    arrows={false}
                    showDots
                    dotListClass="dots-carousel"
                    centerMode={false}
                    className=""
                    containerClass="container-carousel"
                    draggable
                    focusOnSelect={false}
                    minimumTouchDrag={80}
                    partialVisible
                    renderButtonGroupOutside={false}
                    renderDotsOutside={false}
                    responsive={{
                      desktop: {
                        breakpoint: {
                          max: 3000,
                          min: 1024,
                        },
                        items: 3,
                        partialVisibilityGutter: 40,
                      },
                      mobile: {
                        breakpoint: {
                          max: 464,
                          min: 0,
                        },
                        items: 2,
                        partialVisibilityGutter: 10,
                      },
                      tablet: {
                        breakpoint: {
                          max: 1024,
                          min: 464,
                        },
                        items: 2,
                        partialVisibilityGutter: 30,
                      },
                    }}
                    slidesToSlide={2}
                    swipeable
                  >
                    {design_do_produto.edges.map((item, index) => (
                      <a
                        href={
                          props.design === item.node.uid
                            ? `/ambientes/?design=&tonalidade=${props.tonalidade}&tipo-de-projeto=${props.tipo}&mdf=${props.mdfFilter}`
                            : `/ambientes/?design=${item.node.uid}&tonalidade=${props.tonalidade}&tipo-de-projeto=${props.tipo}&mdf=${props.mdfFilter}&is_3d=${props.is3d}`
                        }
                        key={index}
                        className="box-option"
                        style={
                          item.node.data.imagem.url
                            ? {
                                backgroundImage: `url('${item.node.data.imagem.url}')`,
                              }
                            : null
                        }
                      >
                        <div>
                          <span>{item.node.data.nome.text}</span>
                          <i
                            className={
                              props.design === item.node.uid ? "active" : ""
                            }
                          ></i>
                        </div>
                      </a>
                    ))}
                  </Carousel>
                </div>
              ) : (
                <div className="sub-item">
                  {design_do_produto.edges.map((item, index) => (
                    <a
                      href={
                        props.design === item.node.uid
                          ? `/ambientes/?design=&tonalidade=${props.tonalidade}&tipo-de-projeto=${props.tipo}&mdf=${props.mdfFilter}`
                          : `/ambientes/?design=${item.node.uid}&tonalidade=${props.tonalidade}&tipo-de-projeto=${props.tipo}&mdf=${props.mdfFilter}&is_3d=${props.is3d}`
                      }
                      key={index}
                      className="box-option"
                      style={
                        item.node.data.imagem
                          ? {
                              backgroundImage: `url('${item.node.data.imagem.url}')`,
                            }
                          : null
                      }
                    >
                      <div>
                        <span>{item.node.data.nome.text}</span>
                        <i
                          className={
                            props.design === item.node.uid ? "active" : ""
                          }
                        ></i>
                      </div>
                    </a>
                  ))}
                </div>
              )}
            </li>
            <li>
              <div className="header-title" onClick={toggleMenuIs3d}>
                <i className="icon icon-mdf"></i>
                <span>Tipo de Imagem</span>
                <i
                  className={
                    props.is3d.length > 0
                      ? "icon-arrow active-arrow"
                      : "icon-arrow"
                  }
                ></i>
              </div>
              <div className="sub-item">
                <a
                  href={`/ambientes/?design=${props.design}&tonalidade=${
                    props.tonalidade
                  }&tipo-de-projeto=${props.tipo}&mdf=${
                    props.mdfFilter
                  }&is_3d=${
                    props.is3d.length > 0 && props.is3d == 0 ? "" : "0"
                  }`}
                  key={""}
                  className="box-option"
                  style={{
                    backgroundImage: `url('https://images.prismic.io/guararapes/43b992b7-00b6-47a3-8bb8-f54e8855cbbc_quartos_infantis.jpg?auto=compress%2Cformat&fit=max&q=50')`,
                  }}
                >
                  <div>
                    <span>{"Imagens Reais"}</span>
                    <i
                      className={
                        props.is3d.length > 0 && props.is3d == 0
                          ? "active"
                          : null
                      }
                    ></i>
                  </div>
                </a>

                <a
                  href={`/ambientes/?design=${props.design}&tonalidade=${
                    props.tonalidade
                  }&tipo-de-projeto=${props.tipo}&mdf=${
                    props.mdfFilter
                  }&is_3d=${
                    props.is3d.length > 0 && props.is3d == 1 ? "" : "1"
                  }`}
                  key={""}
                  className="box-option"
                  style={{
                    backgroundImage: `url('https://images.prismic.io/guararapes/98c51083-830e-415e-8c07-7b55b11f016f_cozinhas.jpg?auto=compress%2Cformat&fit=max&q=50')`,
                  }}
                >
                  <div>
                    <span>{"Imagens 3D"}</span>
                    <i
                      className={
                        props.is3d.length > 0 && props.is3d == 1
                          ? "active"
                          : null
                      }
                    ></i>
                  </div>
                </a>
              </div>
            </li>
            <li>
              {((props.mdfFilter && props.mdfFilter.length > 0) ||
                (props.tipo && props.tipo.length > 0) ||
                (props.tonalidade && props.tonalidade.length > 0) ||
                (props.design && props.design.length > 0) ||
                props.is3d.length > 0) && (
                <a href="/ambientes/?tonalidade=&design=&mdf=&tipo-de-projeto=&is_3d=">
                  <i></i>
                  <span>Limpar</span>
                </a>
              )}
            </li>
          </ul>
        </div>
      )}
    </Container>
  );
}
