import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useStaticQuery, graphql, Link } from "gatsby";

import GlobalStyle from "../../assets/globalStyles";
import Seo from "../../components/seo";

import ContainerDefault from "../../components/container";
import Header from "../../components/header";
import Footer from "../../components/footer";
import ProjectCard from "../../components/project-card";
import Newsletter from "../../components/newsletter";

import FilterProjects from "./filter";

import api from "../../services/api";
import Project from "../Project";
import { position } from "polished";

const Container = styled.section`
  padding-top: 45px;
  padding-bottom: 35px;

  h2.not-found {
    font: italic bold 32px "Times new roman";
  }

  .projects-list {
    li {
      margin-bottom: 35px;
    }
  }

  .view-more {
    display: flex;
    align-items: center;
    justify-content: center;

    a {
      width: 265px;
    }
  }

  .loadMore {
    width: 265px;
    height: 60px;
    border-radius: 3px;

    cursor: pointer;

    background: #f1a857;

    display: flex;
    align-items: center;

    transition: 0.2s all ease;

    span {
      color: #ffffff;
      font-size: 16px;
      font-weight: 500;
      padding-left: 29px;
      padding-right: 17px;

      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    i {
      width: 55px;
      height: 100%;
      border-left: 1px solid #d99440;
      margin-left: auto;
      background: url("/images/icon-plus-white.svg") center no-repeat;
    }

    &:hover {
      background: #e59134;

      i {
        border-left: 1px solid #cd8226;
      }
    }
  }

  @media (min-width: 767px) {
    padding-bottom: 45px;
    padding-top: 45px;

    .projects-list {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 16px;
      grid-row-gap: 22px;

      li {
        margin-bottom: 0;
      }
    }

    .view-more {
      margin-top: 45px;
    }

    .ReactModal__Content {
      padding: 35px !important;
      left: 40px !important;
      right: 40px !important;
    }
  }

  @media (min-width: 1200px) {
    padding-top: 35px;

    .projects-list {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-column-gap: 16px;
      grid-row-gap: 28px;
    }

    .ReactModal__Content {
      padding: 35px 100px !important;
      max-width: 1200px;
      margin: 0 auto;
    }
  }

  .ReactModal__Content {
    left: 20px !important;
    right: 20px !important;
    border: 0 !important;
  }
`;

const projectsPerPage = 9;
let projectModal = false;
let arrayForHoldingProjects = [];
let allProjects = [];

export default function Projects(props) {
  const results = useStaticQuery(graphql`
    {
      seo: prismicSeoPaginaAmbientesMeuAmbiente {
        data {
          canonical {
            url
          }
          meta_description
          meta_og_description
          meta_og_image {
            url
          }
          meta_og_title
          meta_og_type
          meta_og_url {
            url
          }
          meta_title
        }
      }
    }
  `);

  const { seo } = results;

  const [tipo, setTipo] = useState("");
  const [design, setDesign] = useState("");
  const [tonalidade, setTonalidade] = useState("");
  const [mdf, setMdf] = useState("");
  const [is_3d, setIs3d] = useState("");
  const [loaded, setLoaded] = useState(0);
  const [projectsToShow, setProjectsToShow] = useState([]);
  const [hideLoadButton, setHideLoadButton] = useState(false);
  const [next, setNext] = useState(9);
  const [projectModal, setProjectModal] = useState(false);

  useEffect(() => {
    const search = window.location.search.replace("?", "").split("&");
    let tipo = "";
    let design = "";
    let tonalidade = "";
    let mdf = "";
    let is_3d = "";
    for (var item of search) {
      const node = item.split("=");
      switch (node[0]) {
        case "tipo-de-projeto":
          if (node[1].replace(/%20/g, " ") !== "undefined")
            tipo = decodeURI(node[1].replace(/%20/g, " "));
          break;
        case "design":
          if (node[1].replace(/%20/g, " ") !== "undefined")
            design = decodeURI(node[1].replace(/%20/g, " "));
          break;
        case "tonalidade":
          if (node[1].replace(/%20/g, " ") !== "undefined")
            tonalidade = decodeURI(node[1].replace(/%20/g, " "));
          break;
        case "mdf":
          if (node[1].replace(/%20/g, " ") !== "undefined")
            mdf = decodeURI(node[1].replace(/%20/g, " "));
          break;
        case "is_3d":
          if (node[1].replace(/%20/g, " ") !== "undefined")
            is_3d = decodeURI(node[1].replace(/%20/g, " "));
          break;
        default:
      }
    }
    setMdf(mdf);
    setTonalidade(tonalidade);
    setDesign(design);
    setTipo(tipo);
    setIs3d(is_3d);
    api
      .post("https://apimeuambiente.guararapes.com.br/project/get/all/", {
        tipo: tipo,
        design: design,
        tonalidade: tonalidade,
        mdf: mdf,
        is_3d: is_3d,
      })
      .then((response) => {
        allProjects = response.data;
        arrayForHoldingProjects = [];
        loopWithSlice(0, projectsPerPage, tipo, design, tonalidade, mdf, is_3d);
      });
  }, [props]);

  const loopWithSlice = (start, end, tipo, design, tonalidade, mdf, is_3d) => {
    let projectsFiltered = allProjects;
    if (
      tipo.length > 0 ||
      design.length > 0 ||
      tonalidade.length > 0 ||
      mdf.length > 0 ||
      is_3d.length > 0
    ) {
      projectsFiltered = allProjects.filter(
        (item) =>
          (tipo.length > 0 &&
            item.project_type.toLowerCase() === tipo.toLowerCase()) ||
          (design.length > 0 &&
            item.products.toLowerCase().indexOf(design.toLowerCase()) !== -1) ||
          (tonalidade.length > 0 &&
            item.products.toLowerCase().indexOf(tonalidade.toLowerCase()) !==
              -1) ||
          (mdf.length > 0 &&
            item.products.toLowerCase().indexOf(mdf.toLowerCase()) !== -1) ||
          (is_3d.length > 0 && item.is_3d === is_3d)
      );
    }
    const slicedProjects = projectsFiltered.slice(start, end);
    arrayForHoldingProjects = [...arrayForHoldingProjects, ...slicedProjects];
    setProjectsToShow(arrayForHoldingProjects);

    if (arrayForHoldingProjects.length === allProjects.length) {
      setHideLoadButton(true);
    }
    setLoaded(1);
  };

  const handleShowMoreProjects = () => {
    const search = window.location.search.replace("?", "").split("&");
    let tipo = "";
    let design = "";
    let tonalidade = "";
    let mdf = "";
    let is_3d = "";
    for (var item of search) {
      const node = item.split("=");
      switch (node[0]) {
        case "tipo-de-projeto":
          if (node[1].replace(/%20/g, " ") !== "undefined")
            tipo = decodeURI(node[1].replace(/%20/g, " "));
          break;
        case "design":
          if (node[1].replace(/%20/g, " ") !== "undefined")
            design = decodeURI(node[1].replace(/%20/g, " "));
          break;
        case "tonalidade":
          if (node[1].replace(/%20/g, " ") !== "undefined")
            tonalidade = decodeURI(node[1].replace(/%20/g, " "));
          break;
        case "mdf":
          if (node[1].replace(/%20/g, " ") !== "undefined")
            mdf = decodeURI(node[1].replace(/%20/g, " "));
          break;
        case "is_3d":
          if (node[1].replace(/%20/g, " ") !== "undefined")
            is_3d = decodeURI(node[1].replace(/%20/g, " "));
          break;
        default:
      }
    }
    loopWithSlice(
      next,
      next + projectsPerPage,
      tipo,
      design,
      tonalidade,
      mdf,
      is_3d
    );
    setNext(next + projectsPerPage);
  };

  const handleShowModal = (project) => {
    setProjectModal(project);
  };

  return (
    <>
      {seo && (
        <Seo
          metaTitle={seo.data.meta_title}
          metaDescription={seo.data.meta_description}
          title={seo.meta_og_title}
          description={seo.meta_og_description}
          image={seo.meta_og_image ? seo.meta_og_image.url : ""}
          url={seo.data.meta_og_url ? seo.data.meta_og_url : ""}
          type={seo.meta_og_type}
        />
      )}
      <Header />
      <FilterProjects
        tipo={tipo}
        design={design}
        tonalidade={tonalidade}
        mdfFilter={mdf}
        is3d={is_3d}
      />
      {projectModal !== false && (
        <div
          onClick={(event) => {
            if (event.target.id === "modal-background") {
              handleShowModal(false);
            }
          }}
          className="ReactModal__Overlay ReactModal__Overlay--after-open"
          id="modal-background"
          style={{
            position: "fixed",
            inset: "0px",
            backgroundColor: "rgba(0,0,0,0.6)!important",
            zIndex: 11,
          }}
        >
          <div
            className="ReactModal__Content ReactModal__Content--after-open"
            tabIndex="-1"
            role="dialog"
            aria-modal="true"
            style={{
              position: "absolute",
              inset: "40px",
              border: "0px !important",
              background: "rgb(255, 255, 255)",
              overflow: "auto",
              borderRadius: "4px",
              outline: "none",
            }}
          >
            <Link to="#" className="close-modal">
              <i></i>
            </Link>
            <Project
              pageContext={projectModal}
              modalProp={true}
              modalCloseCallBack={handleShowModal}
            />
          </div>
        </div>
      )}
      <ContainerDefault>
        <Container>
          {projectsToShow.length > 0 ? (
            <ul className="projects-list">
              {projectsToShow.map((project) => (
                <li
                  key={project.id}
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    handleShowModal(project);
                  }}
                >
                  <ProjectCard
                    id={project.id}
                    image={project.gallery}
                    name={project.name}
                    city={project.city}
                    uf={project.uf}
                    authorImage={project.user.company_avatar}
                    authorName={project.user.company_name}
                    numberOfLikes={project.likes}
                    products={project.products}
                    noLink={true}
                  />
                </li>
              ))}
            </ul>
          ) : loaded === 0 ? (
            <h2 className="not-found">Carregando projetos...</h2>
          ) : (
            <h2 className="not-found">Nenhum projeto encontrado</h2>
          )}

          <div className="view-more">
            {!hideLoadButton && projectsToShow.length > 0 && (
              <button
                type="button"
                className="loadMore"
                onClick={handleShowMoreProjects}
              >
                <span>Carregar Mais</span>
                <i></i>
              </button>
            )}
          </div>
        </Container>
      </ContainerDefault>
      <Newsletter />
      <Footer />

      <GlobalStyle />
    </>
  );
}
